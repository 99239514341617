import React from "react"
import tw, { css } from "twin.macro"

import { CampaignStatus } from "@src/queries/campaign"

const campStyles = {
  [CampaignStatus.Draft]: tw`bg-gray-500`,
  [CampaignStatus.Submitted]: tw`bg-black`,
  [CampaignStatus.ForReview]: tw`bg-orange-400`,
  [CampaignStatus.Approved]: tw`bg-green-500`,
  [CampaignStatus.Archived]: tw`bg-gray-500`,
}

interface CampaignStatusProps {
  status: CampaignStatus
}

const Status = ({ status }: CampaignStatusProps): React.ReactElement => (
  <div
    css={[
      tw`text-white text-xs uppercase`,
      css`
        padding: 0.1rem 1.25rem;
      `,
      campStyles[status],
    ]}
  >
    {status}
  </div>
)

export default Status
