import React from "react"
import tw, { css } from "twin.macro"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import { useQuery, useMutation } from "@apollo/client"
import { CheckCircleFilled } from "@ant-design/icons"

import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomModal from "@src/components/ui/modal"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import Loading from "@src/components/loading"
import { GET_CAMPAIGN, Campaign } from "@src/queries/campaign"
import { RepeatEvery } from "../createCampaign/content/templates"
import { Localization } from "@src/localization"
import { APPROVE_CAMPAIGN_RUN } from "@src/queries/campaignRun"

const repeatEveryValues = {
  [RepeatEvery.oneTime]: "One time",
  [RepeatEvery.monthly]: "Monthly",
}

const Title = ({ heading }: { heading: string }) => (
  <CustomModal.Title heading={heading}> </CustomModal.Title>
)

const Footer = ({
  heading,
  onSuccess,
}: {
  heading: string
  onSuccess: () => void
}) => (
  <CustomModal.Footer heading={heading} headingCss={tw`text-green-500!`}>
    <div tw="flex justify-end">
      <CustomButton color={ButtonColors.green} onClick={onSuccess}>
        CONFIRM & APPROVE
      </CustomButton>
    </div>
  </CustomModal.Footer>
)

interface CustomModalProps {
  campaignId?: number
  campaignRunId?: number
}

// eslint-disable-next-line no-empty-pattern
const ApproveCampaignModal = ({
  campaignId,
  campaignRunId,
}: CustomModalProps & RouteComponentProps): React.ReactElement => {
  // page content
  const submitContent = React.useContext(Localization).submitCampaign
  const approveContent = React.useContext(Localization).approveCampaign

  // approve campaign run
  const [approveCampaignRunMutation, approveCampaignRunResp] = useMutation(
    APPROVE_CAMPAIGN_RUN
  )

  // get campaigns
  const getCampaignResp = useQuery(GET_CAMPAIGN, {
    variables: {
      campaignId,
    },
    skip: !campaignId,
  })

  // campaign
  const campaign =
    getCampaignResp &&
    getCampaignResp.data &&
    (getCampaignResp.data.campaign as Campaign)

  // campaign run
  const campaignRun =
    campaign &&
    campaignRunId &&
    campaign.campaignRuns &&
    campaign.campaignRuns.length > 0 &&
    campaign.campaignRuns.find(cr => cr.id == campaignRunId)

  // submit campaign
  const approveCampaign = async () => {
    // approve campaign
    await approveCampaignRunMutation({
      variables: {
        campaignRunId,
        body: {},
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGN,
          variables: { campaignId: campaign.id },
        },
      ],
    })
  }

  // show loading if no campaign
  if (!campaign || !campaignRun) return <Loading withOverlay={true} />

  return (
    <>
      {(getCampaignResp.loading || approveCampaignRunResp.loading) && (
        <Loading withOverlay={true} />
      )}
      <CustomModal
        title={<Title heading={approveContent.heading} />}
        isVisible={true}
        onCancel={() => navigate(navigationRoutes[PageOptions.CAMPAIGNS])}
        footer={
          <Footer
            heading={approveContent.footerHeading}
            onSuccess={async () => {
              await approveCampaign()
              navigate(navigationRoutes[PageOptions.CAMPAIGNS])
            }}
          />
        }
        width={700}
      >
        <div tw="flex flex-col px-16 pt-8 pb-4 text-black">
          <p
            css={[tw`text-sm`]}
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              __html: approveContent.description,
            }}
          ></p>

          <div css={[tw`flex mt-8 font-bold text-base`]}>
            {submitContent.campaign}: {campaign.name}
          </div>

          <div tw="flex flex-wrap">
            {campaign.templates.map((temp, index) => {
              // get template run
              const tempRun =
                campaignRun &&
                campaignRun.templateRuns &&
                campaignRun.templateRuns.length > 0 &&
                campaignRun.templateRuns.find(tr => {
                  return tr.templateId == temp.id
                })
              console.log("tempRun", tempRun)

              if (!tempRun) return null

              return (
                <div tw="flex flex-col w-1/2 text-black pt-2" key={temp.id}>
                  <div tw="text-sm font-bold">
                    {submitContent.template} {index + 1}: {temp.name}
                  </div>
                  <div tw="flex flex-col text-xs">
                    <div tw="flex items-center pt-1">
                      <CheckCircleFilled />
                      <span tw="ml-2">
                        {submitContent.size}: {temp.cardSize}{" "}
                        {submitContent.inch}
                      </span>
                    </div>
                    <div tw="flex items-center pt-1">
                      <CheckCircleFilled />
                      <span tw="ml-2">
                        {submitContent.schedToPrint}:{" "}
                        <span tw="font-bold">
                          {
                            repeatEveryValues[
                              campaign.repeatEveryDays as RepeatEvery
                            ]
                          }
                        </span>
                      </span>
                    </div>
                    <div tw="flex items-center pt-1">
                      <CheckCircleFilled />
                      <span tw="ml-2">
                        {submitContent.timeToProduce}:{" "}
                        <span tw="font-bold">5 Business Days</span>
                      </span>
                    </div>
                    {tempRun && (
                      <div tw="flex items-center pt-1">
                        <CheckCircleFilled />
                        <span tw="ml-2">
                          {approveContent.numberOfRecipients}:{" "}
                          <span tw="font-bold">
                            {tempRun.totalPatients - tempRun.rejectedPatients}
                          </span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
            {/* <div tw="flex flex-col w-1/2 text-black pt-2">
              <div tw="ml-2">
                {approveContent.totalRecipients}:{" "}
                <span tw="font-bold">1585</span>
              </div>
            </div> */}
          </div>

          <div tw="flex mt-6">
            <div
              css={[
                tw`flex w-1/2`,
                css`
                  font-size: 0.55rem;
                `,
              ]}
            >
              {approveContent.secondaryDesc}
            </div>
            <div tw="flex w-1/2 text-xs justify-end items-center">
              {submitContent.mailingFreqInfo}
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  )
}

export default ApproveCampaignModal
