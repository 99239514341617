import React from "react"
import tw from "twin.macro"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"

import CustomModal from "@src/components/ui/modal"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import { Localization } from "@src/localization"

const Title = ({ heading }: { heading: string }) => (
  <CustomModal.Title heading={heading}> </CustomModal.Title>
)

// eslint-disable-next-line no-empty-pattern
const SubmittedCampaignModal = ({}: RouteComponentProps): React.ReactElement => {
  // page content
  const submitContent = React.useContext(Localization).submitCampaign

  return (
    <CustomModal
      title={<Title heading={submitContent.submittedHeading} />}
      isVisible={true}
      onCancel={() => navigate(navigationRoutes[PageOptions.CAMPAIGNS])}
      width={380}
      footer={null}
    >
      <div tw="flex flex-col px-16 py-8 text-black">
        <p
          css={[tw`text-base text-center`]}
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            __html: submitContent.submittedDescription,
          }}
        />
      </div>
    </CustomModal>
  )
}

export default SubmittedCampaignModal
