import React from "react"
import tw, { css } from "twin.macro"
import { useQuery, useMutation } from "@apollo/client"
import { Tooltip, Collapse, Form, Input, Button } from "antd"
import {
  InfoCircleOutlined,
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons"
import ContentEditable from "react-contenteditable"
import { navigate } from "gatsby"
import { Router } from "@reach/router"
import { serverToClient } from "@src/utils/timezone"

import CampaignNavigation from "@src/components/campaign/navigation"
import InfoBarWithContext from "@src/components/infoBar/infoBarWithContext"
import CustomContainer from "@src/components/customContainer"
import Loading from "@src/components/loading"
import {
  GET_CAMPAIGNS,
  Campaign,
  CampaignStatus,
  UPDATE_CAMPAIGN,
  DUPLICATE_CAMPAIGN,
} from "@src/queries/campaign"
import { GET_USER, UPDATE_NEW_COLUMN_LOCATION, User } from "@src/queries/user"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import ErrorComponent from "@src/components/ui/error"
import { createUrl } from "@src/utils/createUrl"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import Status from "@src/sections/campaigns/status"
import SubmitCampaignModal from "@src/sections/campaigns/submit"
import SubmittedCampaignModal from "./submitted"
import { TemplateRun } from "@src/queries/template"
import config from "@src/config"
import {
  UPDATE_CAMPAIGN_RUN_NOTES,
  CampaignRunStatus,
} from "@src/queries/campaignRun"
import { getToken } from "@src/utils/auth"
import { Localization } from "@src/localization"
import ApproveCampaignModal from "./approve"
import { getLastModified } from "@src/utils/lastModified"

const { Panel } = Collapse

const Campaigns = (): React.ReactElement => {
  // active key
  const [activeKey, setActiveKey] = React.useState([])

  // is editing
  const [isEditingName, setIsEditingName] = React.useState<boolean>(false)
  const [isEditingNotes, setIsEditingNotes] = React.useState<boolean>(false)

  // is new location
  const [isNewLocation, setIsNewLocation] = React.useState<boolean>(false)

  // page content
  const campaignsContent = React.useContext(Localization).campaigns

  // get user
  const getUserResp = useQuery(GET_USER)

  const user: User =
    getUserResp && getUserResp.data && (getUserResp.data.user as User)

  // get campaigns
  const { loading, error, data } = useQuery(GET_CAMPAIGNS)

  // update campaign
  const [updateCampaignMutation, updateCampaignResp] = useMutation(
    UPDATE_CAMPAIGN
  )

  // duplicate campaign
  const [duplicateCampaignMutation, duplicateCampaignResp] = useMutation(
    DUPLICATE_CAMPAIGN
  )

  // update campaign run notes
  const [updateCampaignRunMutation, updateCampaignRunResp] = useMutation(
    UPDATE_CAMPAIGN_RUN_NOTES
  )

  // update campaign run notes
  const [updateNewColumnLocationUserMutation] = useMutation(
    UPDATE_NEW_COLUMN_LOCATION
  )

  // update new column of user location
  const updateNewColumnLocation = async () => {
    await updateNewColumnLocationUserMutation({
      variables: {
        body: {},
        refetchQueries: [
          {
            query: GET_USER,
          },
        ],
      },
    })

    setIsNewLocation(false)
  }

  React.useEffect(() => {
    if (user && user.locations) {
      const newLocationIndex = user.locations.findIndex(
        location => location.new
      )
      if (newLocationIndex > -1) {
        setIsNewLocation(true)
      }
    }
  }, [user])

  // form
  const [form] = Form.useForm()

  if (loading) return <Loading withOverlay={true} />

  // show error page
  if (error)
    return (
      <ErrorComponent msg="There is an error fetching campaigns. Please refresh your page or try again later." />
    )

  // sort campaigns
  const sortedCampaigns: Campaign[] = []
  if (data && data.campaigns && data.campaigns.length > 0) {
    data.campaigns.slice().forEach((oneCamp: Campaign) => {
      if (oneCamp.campaignRuns && oneCamp.campaignRuns.length > 0) {
        oneCamp.campaignRuns.forEach(oneRun => {
          sortedCampaigns.push({
            ...oneCamp,
            campaignRuns: [oneRun],
          })
        })
      } else {
        sortedCampaigns.push(oneCamp)
      }
    })
  }
  if (sortedCampaigns && sortedCampaigns.length > 0) {
    sortedCampaigns.sort((a: Campaign, b: Campaign) => {
      return getLastModified(a) > getLastModified(b) ? -1 : 1
    })
  }

  // update campaign
  const updateCampaign = async ({ campaign }: { campaign: Campaign }) => {
    // update campaign
    await updateCampaignMutation({
      variables: {
        campaignId: campaign.id,
        body: {
          ...campaign,
        },
      },
    })
  }

  // update campaign
  const updateCampaignRun = async ({
    crId,
    notes,
  }: {
    crId: number
    notes: string
  }) => {
    // update campaign
    await updateCampaignRunMutation({
      variables: {
        campaignRunId: crId,
        body: {
          notes,
        },
      },
    })
  }

  // duplicate campaign
  const duplicateCampaign = async (campaignId: number) => {
    // duplicate campaign
    await duplicateCampaignMutation({
      variables: {
        campaignId,
        body: {},
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGNS,
        },
      ],
    })
  }

  return (
    <CustomContainer customCss={tw`bg-white`}>
      {(updateCampaignResp.loading ||
        duplicateCampaignResp.loading ||
        updateCampaignRunResp.loading) && <Loading withOverlay={true} />}

      <div css={[tw`flex flex-col w-full`]}>
        {/* Campaign navigation */}
        <CampaignNavigation />

        {/* Campaign orange info bar */}
        <InfoBarWithContext />

        {isNewLocation && (
          <div css={[tw`text-green-500 mb-4 flex items-center justify-center`]}>
            <InfoCircleOutlined css={tw`text-green-500 text-xl`} />

            <p css={tw`text-xs font-bold mx-2`}>
              A New Location has been added to your practice. All default
              mapping will automatically be applied to this new location.
            </p>

            <Button
              css={tw`flex items-center justify-center bg-white hover:text-black hover:border-transparent hover:bg-gray-300 border-gray-400 text-black`}
              shape="circle"
              onClick={updateNewColumnLocation}
            >
              <CloseOutlined />
            </Button>
          </div>
        )}

        {/* Submit campaign modal */}
        <Router>
          <SubmitCampaignModal
            path={navigationRoutes[PageOptions.SUBMITCAMPAIGN]}
          />
          <SubmitCampaignModal
            path={navigationRoutes[PageOptions.RESUBMITCAMPAIGN]}
          />
          <SubmittedCampaignModal
            path={navigationRoutes[PageOptions.SUBMITTEDCAMPAIGN]}
          />
          <ApproveCampaignModal
            path={navigationRoutes[PageOptions.APPROVECAMPAIGN]}
          />
        </Router>

        <div tw="flex flex-col self-center w-full xl:w-10/12">
          <div tw="flex text-xs text-gray-800 text-center uppercase">
            <div tw="w-6/12 pl-24 text-left">{campaignsContent.campaigns}</div>
            <div tw="w-4/12">{campaignsContent.action}</div>
            <div tw="w-2/12">{campaignsContent.status}</div>
          </div>
        </div>

        <div tw="flex flex-col self-center w-full xl:w-10/12 min-h-md bg-white border border-gray-400 rounded-lg mb-4">
          <Form name="campaigns" form={form}>
            <Collapse
              accordion
              activeKey={activeKey}
              onChange={(key: any) => {
                setActiveKey(key)
                setIsEditingName(false)
                setIsEditingNotes(false)
              }}
              css={[
                tw`flex flex-col self-center w-full text-black bg-white border-none rounded-lg`,
                css`
                  & .ant-collapse-header {
                    padding: 0px !important;
                  }
                  & .ant-collapse-content-box {
                    padding: 0px !important;
                  }
                `,
              ]}
            >
              {sortedCampaigns.map((oneCampaign: Campaign) => {
                // are there multiple templates
                const multipleTemplates =
                  oneCampaign.templates && oneCampaign.templates.length > 1

                // template name
                const templateName =
                  oneCampaign.templates && oneCampaign.templates.length > 0
                    ? oneCampaign.templates[0].name
                    : "-"

                // name change required
                const isNameChangeReq = oneCampaign.name
                  .toLowerCase()
                  .includes("- copy")

                // sorted templates
                const sortedTemplates =
                  oneCampaign.templates &&
                  oneCampaign.templates.slice().sort((a, b) => {
                    return a.modifiedDate > b.modifiedDate ? 1 : -1
                  })

                // campaign last edited
                const lastEdited = serverToClient(
                  getLastModified(oneCampaign)
                ).format("llll z")

                // campaign status
                let status: CampaignStatus = CampaignStatus.Draft
                if (
                  oneCampaign.campaignRuns &&
                  oneCampaign.campaignRuns.length > 0
                ) {
                  switch (oneCampaign.campaignRuns[0].status) {
                    case CampaignRunStatus.WaitingForPatients:
                    case CampaignRunStatus.PatientsReceived:
                    case CampaignRunStatus.DeDuplicationDone:
                    case CampaignRunStatus.SubmittedForProof:
                      status = CampaignStatus.Submitted
                      break
                    case CampaignRunStatus.ReceivedProof:
                      status = CampaignStatus.ForReview
                      break
                    case CampaignRunStatus.Approved:
                    case CampaignRunStatus.Sent:
                      status = CampaignStatus.Approved
                      break
                    case CampaignRunStatus.Archived:
                      status = CampaignStatus.Archived
                      break
                  }
                }

                // don't render archived ones
                if (status === CampaignStatus.Archived) return null

                // campaign run identifier
                const crId =
                  oneCampaign.campaignRuns &&
                  oneCampaign.campaignRuns.length > 0
                    ? oneCampaign.campaignRuns[0].id
                    : ""

                return (
                  <Panel
                    showArrow={false}
                    key={`${oneCampaign.id}${crId}`}
                    header={
                      <div tw="flex w-full">
                        <div tw="flex flex-col w-6/12 py-3 px-3">
                          <div tw="font-bold">{oneCampaign.name}</div>
                          <div tw="text-xs text-gray-800">
                            {campaignsContent.campaignId}:{" "}
                            <span tw="font-bold text-black">
                              {oneCampaign.id}
                            </span>
                          </div>
                          <div tw="text-xs text-gray-800">
                            {campaignsContent.template}:{" "}
                            <span tw="font-bold text-black">
                              {templateName}
                              {multipleTemplates && <>, ...</>}
                            </span>
                          </div>
                          <div tw="text-xs text-gray-800">
                            {campaignsContent.edited}{" "}
                            <span tw="font-bold text-black">{lastEdited}</span>{" "}
                          </div>
                        </div>
                        <div
                          css={[
                            tw`flex w-4/12 border border-t-0 border-b-0 border-gray-400 items-center justify-center`,
                          ]}
                        >
                          {/* duplicate button */}
                          <CustomButton
                            disabled={[CampaignStatus.Draft].includes(status)}
                            color={ButtonColors.yellow}
                            onClick={e => {
                              e.stopPropagation()
                              duplicateCampaign(oneCampaign.id)
                            }}
                          >
                            {campaignsContent.duplicate}
                          </CustomButton>
                          <Tooltip
                            placement="topRight"
                            title={campaignsContent.duplicateInfo}
                            overlayStyle={{
                              fontSize: "0.8rem",
                            }}
                            css={tw`mx-4`}
                            arrowPointAtCenter
                          >
                            <InfoCircleOutlined />
                          </Tooltip>

                          {/* review/edit button */}
                          {[CampaignStatus.Draft].includes(status) ? (
                            <CustomButton color={ButtonColors.white}>
                              <EditOutlined tw="mr-2" />
                              {campaignsContent.edit}
                            </CustomButton>
                          ) : (
                            <CustomButton color={ButtonColors.white}>
                              {campaignsContent.review}
                            </CustomButton>
                          )}
                        </div>

                        {/* status */}
                        <div tw="flex w-2/12 items-center justify-center">
                          <Status status={status} />
                        </div>
                      </div>
                    }
                  >
                    <div tw="flex flex-col text-black">
                      {/* campaign name */}
                      <div tw="flex flex-col py-2">
                        <div tw="flex px-6 py-2 items-center w-full">
                          <div tw="text-sm pr-4 font-bold">
                            {campaignsContent.campaignName}:
                          </div>
                          <div tw="text-gray-800 flex-grow">
                            <Form.Item
                              name={[oneCampaign.id, "name"]}
                              css={tw`hidden!`}
                            >
                              <Input />
                            </Form.Item>
                            <ContentEditable
                              html={oneCampaign.name} // innerHTML of the editable div
                              disabled={!isEditingName} // use true to disable edition
                              onChange={e => {
                                form.setFieldsValue({
                                  [[oneCampaign.id, "name"].join(".")]: e.target
                                    .value,
                                })
                              }}
                              style={{ outline: "none" }}
                              css={[
                                tw`border`,
                                isEditingName
                                  ? tw`border-gray-500`
                                  : tw`border-transparent`,
                              ]}
                            />
                          </div>
                          {[CampaignStatus.Draft].includes(status) && (
                            <>
                              {!isEditingName ? (
                                <CustomButton
                                  color={ButtonColors.white}
                                  onClick={() => {
                                    setIsEditingName(true)
                                  }}
                                >
                                  <EditOutlined tw="mr-2" />
                                  {campaignsContent.editName}
                                </CustomButton>
                              ) : (
                                <CustomButton
                                  color={ButtonColors.orange}
                                  onClick={() => {
                                    updateCampaign({
                                      campaign: {
                                        ...oneCampaign,
                                        name: form.getFieldValue(
                                          [oneCampaign.id, "name"].join(".")
                                        ),
                                      },
                                    })
                                    setIsEditingName(false)
                                  }}
                                >
                                  {campaignsContent.saveName}
                                </CustomButton>
                              )}
                            </>
                          )}
                        </div>
                        {isNameChangeReq && (
                          <div tw="flex text-red-600 w-full px-6 justify-end text-sm">
                            {campaignsContent.requiredChangeName}
                          </div>
                        )}
                      </div>

                      {/* campaign templates */}
                      {sortedTemplates &&
                        sortedTemplates.length > 0 &&
                        sortedTemplates.map((template, tempIndex) => {
                          let tempRun: TemplateRun | undefined
                          if (
                            oneCampaign.campaignRuns &&
                            oneCampaign.campaignRuns.length > 0 &&
                            oneCampaign.campaignRuns[0].templateRuns
                          ) {
                            tempRun = oneCampaign.campaignRuns[0].templateRuns.find(
                              tr => {
                                return tr.templateId === template.id
                              }
                            )
                          }

                          return (
                            <div
                              tw="flex px-6 py-4 items-center border-t border-gray-300"
                              key={template.id}
                            >
                              <div tw="text-sm pr-4 font-bold">
                                {campaignsContent.campaignTemplate}:
                                <div tw="text-xs pr-4 font-bold">
                                  {campaignsContent.campaignTemplateId}:
                                </div>
                              </div>
                              <div tw="text-gray-800 flex-grow">
                                <ContentEditable
                                  html={template.name} // innerHTML of the editable div
                                  disabled={true} // use true to disable edition
                                  onChange={e => {
                                    console.log("change", e)
                                  }}
                                  style={{ outline: "none" }}
                                />
                                <div tw="text-xs text-gray-800 flex-grow">
                                  {template.id}
                                </div>
                              </div>

                              {[
                                CampaignStatus.ForReview,
                                CampaignStatus.Approved,
                              ].includes(status) &&
                                tempRun &&
                                crId && (
                                  <>
                                    <CustomButton
                                      color={ButtonColors.white}
                                      onClick={() => {
                                        tempRun &&
                                          window.open(
                                            `${
                                              config.api_base_url
                                            }/v1/templates/${
                                              tempRun.id
                                            }/patients/download?sessionid=${getToken()}`
                                          )
                                      }}
                                    >
                                      {campaignsContent.downloadPatient}
                                    </CustomButton>
                                    <div tw="mx-2" />
                                    <CustomButton
                                      color={ButtonColors.white}
                                      onClick={() => {
                                        tempRun &&
                                          window.open(
                                            `${config.storage_url}/${config.bucket}/proofs/${tempRun.proofFileUrl}`
                                          )
                                      }}
                                    >
                                      {campaignsContent.downloadProof}
                                    </CustomButton>
                                  </>
                                )}

                              {[CampaignStatus.Draft].includes(status) && (
                                <>
                                  {sortedTemplates.length === tempIndex + 1 && (
                                    <CustomButton
                                      color={ButtonColors.white}
                                      customCss={tw`mr-2`}
                                      onClick={() => {
                                        navigate(
                                          createUrl(
                                            navigationRoutes[
                                              PageOptions.ADDTEMPLATE
                                            ],
                                            {
                                              campaignId: oneCampaign.id,
                                              templateId: template.id,
                                            }
                                          )
                                        )
                                      }}
                                    >
                                      <PlusOutlined tw="mr-2" />{" "}
                                      {campaignsContent.addTemplate}
                                    </CustomButton>
                                  )}
                                  <CustomButton
                                    color={ButtonColors.white}
                                    onClick={() => {
                                      navigate(
                                        createUrl(
                                          navigationRoutes[PageOptions.DETAILS],
                                          {
                                            campaignId: oneCampaign.id,
                                            templateId: template.id,
                                          }
                                        )
                                      )
                                    }}
                                  >
                                    <EditOutlined tw="mr-2" />{" "}
                                    {campaignsContent.editTemplate}
                                  </CustomButton>
                                </>
                              )}
                            </div>
                          )
                        })}

                      {/* campaign notes */}
                      <div tw="flex px-6 py-4 items-center border-t border-gray-300">
                        <div tw="text-sm pr-4 font-bold">
                          {campaignsContent.notes}:
                        </div>

                        <div tw="text-gray-800 flex-grow">
                          <Form.Item
                            name={[oneCampaign.id, `${crId}notes`]}
                            css={tw`hidden!`}
                          >
                            <Input />
                          </Form.Item>

                          <ContentEditable
                            html={
                              crId
                                ? oneCampaign.campaignRuns[0].notes
                                : oneCampaign.notes
                            }
                            disabled={!isEditingNotes}
                            onChange={e => {
                              form.setFieldsValue({
                                [[oneCampaign.id, `${crId}notes`].join(".")]: e
                                  .target.value,
                              })
                            }}
                            style={{ outline: "none" }}
                            css={[
                              tw`border`,
                              isEditingNotes
                                ? tw`border-gray-500`
                                : tw`border-transparent`,
                            ]}
                          />
                        </div>
                        {[
                          CampaignStatus.Draft,
                          CampaignStatus.ForReview,
                        ].includes(status) && (
                          <>
                            {!isEditingNotes ? (
                              <CustomButton
                                color={ButtonColors.white}
                                onClick={() => {
                                  setIsEditingNotes(true)
                                }}
                              >
                                <PlusOutlined tw="mr-2" />{" "}
                                {campaignsContent.addNotes}
                              </CustomButton>
                            ) : (
                              <CustomButton
                                color={ButtonColors.orange}
                                onClick={() => {
                                  const notes = form.getFieldValue(
                                    [oneCampaign.id, `${crId}notes`].join(".")
                                  )

                                  if (crId) {
                                    // updateCampaignRunNotes
                                    updateCampaignRun({
                                      crId,
                                      notes,
                                    })
                                  } else {
                                    updateCampaign({
                                      campaign: {
                                        ...oneCampaign,
                                        notes,
                                      },
                                    })
                                  }
                                  setIsEditingNotes(false)
                                }}
                              >
                                {campaignsContent.saveNotes}
                              </CustomButton>
                            )}
                          </>
                        )}
                      </div>

                      <div tw="flex h-16 border-t border-gray-300 w-full"></div>

                      {/* action items */}
                      <div tw="flex justify-end border-t border-gray-300 px-6 py-4">
                        <CustomButton
                          color={ButtonColors.white}
                          customCss={tw`mr-2 uppercase`}
                          onClick={() => setActiveKey([])}
                        >
                          {campaignsContent.close}
                        </CustomButton>

                        {/* submit/re-submit button */}
                        {[CampaignStatus.Draft].includes(status) ? (
                          <CustomButton
                            color={ButtonColors.orange}
                            customCss={tw`mr-2 uppercase`}
                            disabled={isNameChangeReq}
                            onClick={() => {
                              navigate(
                                createUrl(
                                  navigationRoutes[PageOptions.SUBMITCAMPAIGN],
                                  {
                                    campaignId: oneCampaign.id,
                                  }
                                )
                              )
                            }}
                          >
                            {campaignsContent.submit}
                          </CustomButton>
                        ) : (
                          <CustomButton
                            color={ButtonColors.orange}
                            customCss={tw`mr-2 uppercase`}
                            disabled={
                              ![
                                CampaignStatus.Draft,
                                CampaignStatus.ForReview,
                              ].includes(status) || isNameChangeReq
                            }
                            onClick={() => {
                              crId &&
                                navigate(
                                  createUrl(
                                    navigationRoutes[
                                      PageOptions.RESUBMITCAMPAIGN
                                    ],
                                    {
                                      campaignId: oneCampaign.id,
                                      campaignRunId: crId,
                                    }
                                  )
                                )
                            }}
                          >
                            {campaignsContent.resubmit}
                          </CustomButton>
                        )}

                        {/* approve button */}
                        <CustomButton
                          color={ButtonColors.green}
                          customCss={tw`mr-2 uppercase`}
                          disabled={
                            ![CampaignStatus.ForReview].includes(status) ||
                            isNameChangeReq ||
                            !crId
                          }
                          onClick={() => {
                            crId &&
                              navigate(
                                createUrl(
                                  navigationRoutes[PageOptions.APPROVECAMPAIGN],
                                  {
                                    campaignId: oneCampaign.id,
                                    campaignRunId: crId,
                                  }
                                )
                              )
                          }}
                        >
                          {campaignsContent.approve}
                        </CustomButton>
                      </div>
                    </div>
                  </Panel>
                )
              })}
            </Collapse>
          </Form>
          <div tw="px-4 py-8 text-center">
            {campaignsContent.noOtherCampaigns}
          </div>
        </div>
      </div>
    </CustomContainer>
  )
}

export default Campaigns
