import React from "react"
import tw from "twin.macro"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import { useQuery, useMutation } from "@apollo/client"
import { CheckCircleFilled } from "@ant-design/icons"
// import htmlToImage from 'html-to-image'

import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomModal from "@src/components/ui/modal"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import Loading from "@src/components/loading"
import {
  GET_CAMPAIGN,
  SUBMIT_CAMPAIGN,
  Campaign,
  RE_SUBMIT_CAMPAIGN,
} from "@src/queries/campaign"
import { RepeatEvery } from "../createCampaign/content/templates"
import { createUrl } from "@src/utils/createUrl"
import { Localization } from "@src/localization"

// const getImage = async (el: HTMLElement) => {
//   try {
//     const dataUrl = await htmlToImage.toJpeg(el)
//     const img = new Image();
//     img.src = dataUrl;
//     document.body.appendChild(img);
//   } catch(e) {
//     console.error(e);
//     throw e
//   }
// }

const repeatEveryValues = {
  [RepeatEvery.oneTime]: "One time",
  [RepeatEvery.monthly]: "Monthly",
}

const Title = ({ heading }: { heading: string }) => (
  <CustomModal.Title heading={heading}> </CustomModal.Title>
)

const Footer = ({
  heading,
  onSuccess,
}: {
  heading: string
  onSuccess: () => void
}) => (
  <CustomModal.Footer heading={heading}>
    <div tw="flex justify-end">
      <CustomButton color={ButtonColors.orange} onClick={onSuccess}>
        CONFIRM & SUBMIT
      </CustomButton>
    </div>
  </CustomModal.Footer>
)

interface CustomModalProps {
  campaignId?: number
  campaignRunId?: number
}

// eslint-disable-next-line no-empty-pattern
const SubmitCampaignModal = ({
  campaignId,
  campaignRunId,
}: CustomModalProps & RouteComponentProps): React.ReactElement => {
  // page content
  const submitContent = React.useContext(Localization).submitCampaign

  // submit campaign
  const [submitCampaignMutation, submitCampaignResp] = useMutation(
    SUBMIT_CAMPAIGN
  )

  // re-submit campaign
  const [reSubmitCampaignMutation, reSubmitCampaignResp] = useMutation(
    RE_SUBMIT_CAMPAIGN
  )

  // get campaigns
  const getCampaignResp = useQuery(GET_CAMPAIGN, {
    variables: {
      campaignId,
    },
    skip: !campaignId,
  })

  // campaign
  const campaign =
    getCampaignResp &&
    getCampaignResp.data &&
    (getCampaignResp.data.campaign as Campaign)

  // submit campaign
  const submitCampaign = async () => {
    // update campaign
    await submitCampaignMutation({
      variables: {
        campaignId: campaign.id,
        body: {},
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGN,
          variables: { campaignId: campaign.id },
        },
      ],
    })
  }

  // re0submit campaign
  const reSubmitCampaign = async () => {
    // update campaign
    await reSubmitCampaignMutation({
      variables: {
        campaignId: campaign.id,
        campaignRunId,
        body: {},
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGN,
          variables: { campaignId: campaign.id },
        },
      ],
    })
  }

  // show loading if no campaign
  if (!campaign) return <Loading withOverlay={true} />

  return (
    <>
      {(getCampaignResp.loading ||
        submitCampaignResp.loading ||
        reSubmitCampaignResp.loading) && <Loading withOverlay={true} />}
      <CustomModal
        title={<Title heading={submitContent.heading} />}
        isVisible={true}
        onCancel={() => navigate(navigationRoutes[PageOptions.CAMPAIGNS])}
        footer={
          <Footer
            heading={submitContent.footerHeading}
            onSuccess={async () => {
              campaignRunId ? await reSubmitCampaign() : await submitCampaign()

              campaignId &&
                navigate(
                  createUrl(navigationRoutes[PageOptions.SUBMITTEDCAMPAIGN], {
                    campaignId,
                  })
                )
            }}
          />
        }
        width={700}
      >
        <div tw="flex flex-col px-16 pt-8 pb-4 text-black">
          <p
            css={[tw`text-sm`]}
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              __html: submitContent.description,
            }}
          ></p>

          <div css={[tw`flex mt-8 font-bold text-base`]}>
            {submitContent.campaign}: {campaign.name}
          </div>

          <div tw="flex flex-wrap">
            {campaign.templates.map((temp, index) => (
              <div tw="flex flex-col w-1/2 text-black pt-2" key={temp.id}>
                <div tw="text-sm font-bold">
                  {submitContent.template} {index + 1}: {temp.name}
                </div>
                <div tw="flex flex-col text-xs">
                  <div tw="flex items-center pt-1">
                    <CheckCircleFilled />
                    <span tw="ml-2">
                      {submitContent.size}: {temp.cardSize} {submitContent.inch}
                    </span>
                  </div>
                  <div tw="flex items-center pt-1">
                    <CheckCircleFilled />
                    <span tw="ml-2">
                      {submitContent.schedToPrint}:{" "}
                      <span tw="font-bold">
                        {
                          repeatEveryValues[
                            campaign.repeatEveryDays as RepeatEvery
                          ]
                        }
                      </span>
                    </span>
                  </div>
                  <div tw="flex items-center pt-1">
                    <CheckCircleFilled />
                    <span tw="ml-2">
                      {submitContent.timeToProduce}:{" "}
                      <span tw="font-bold">5 Business Days</span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div tw="flex mt-6 text-xs justify-end">
            {submitContent.mailingFreqInfo}
          </div>
        </div>
      </CustomModal>
    </>
  )
}

export default SubmitCampaignModal
